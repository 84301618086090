var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { formatPrice } from 'frontend-utils';
var transformCategoryBreadcrumb = function (breadcrumbs) {
    if (breadcrumbs === void 0) { breadcrumbs = []; }
    return breadcrumbs
        .filter(function (_a) {
        var url = _a.url;
        return !url || url !== '/';
    })
        .map(function (_a) {
        var name = _a.name;
        return name;
    })
        .join(' | ');
};
/**
 * Takes article data and returns an object with the properties needed for tracking.
 *
 * {@link https://github.com/Home24/GraphQL-Api/blob/3b037fedaf4b09399ae9d9a2eb16b71862ce4049/internal/pkg/resolvers/article.go#L957 GQL article resolver}
 */
export var articleToTrackingProps = function (article) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var productPrice = (_f = (_c = (_b = (_a = article.prices) === null || _a === void 0 ? void 0 : _a.special) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : (_e = (_d = article.prices) === null || _d === void 0 ? void 0 : _d.regular) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : 0;
    var categoryBreadcrumb = transformCategoryBreadcrumb(article.breadcrumbs);
    var productCampaigns = (_h = (_g = article.campaigns) === null || _g === void 0 ? void 0 : _g.map(function (campaign) { return campaign.key; })) !== null && _h !== void 0 ? _h : [];
    return __assign(__assign({ productPrice: productPrice, categoryBreadcrumb: categoryBreadcrumb, productCampaigns: productCampaigns, productBrand: (_k = (_j = article.brand) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : '', productDeliveryTime: (_o = (_m = (_l = article.delivery) === null || _l === void 0 ? void 0 : _l.time) === null || _m === void 0 ? void 0 : _m.numberOfDays) !== null && _o !== void 0 ? _o : 0, productName: article.name, productSimpleSKU: article.sku, resultId: article.trackingId, variantName: article.variantName, productConfigSKU: article.parentSku }, (article.productShippingCharge && {
        productShippingCharge: formatPrice(article.productShippingCharge),
    })), (((_q = (_p = article.bestOffer) === null || _p === void 0 ? void 0 : _p.shop) === null || _q === void 0 ? void 0 : _q.id) && {
        shopId: article.bestOffer.shop.id,
    }));
};
